import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import {
  setFlex,
  setRem,
  setColor,
  setLetterSpacing,
  media,
} from "../styles/styles"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = ({ data }) => {
  return (
    <Layout>
      <SEO title="Contact" />
      <ContactWrapper>
        <section>
          <div className="title-container">
            <h1>Contact</h1>
          </div>
          <div className="info">
            I am always interested in new opportunities. If you'd like to get in
            touch about working with me I'd love to hear from you! Please
            contact me via the email below.
            <a href="mailto:alexhcurtin@gmail.com">alexhcurtin@gmail.com</a>
          </div>
        </section>
      </ContactWrapper>
    </Layout>
  )
}

const ContactWrapper = styled.main`
  ${setFlex()};
  margin-bottom: ${setRem(64)};
  section {
    width: 90%;
    max-width: ${setRem(600)};
    padding: ${setRem(48)};
    ${setFlex()};
    border: 1px solid ${setColor.darkGrey};
    border-radius: 2px;
  }
  .title-container {
    width: ${setRem(150)};
    height: ${setRem(150)};
    padding: ${setRem()};
    ${setFlex({ x: "flex-end" })};
  }
  div {
    margin: 0 ${setRem()};
  }
  .info {
    color: ${setColor.darkGrey};
    ${setLetterSpacing(1.2)};
    line-height: ${setRem(20)};
    flex: 3;
    font-weight: 400;
  }

  a {
    display: block;
    margin-top: ${setRem(8)};
    font-size: ${setRem(18)};
    color: ${setColor.mainBlack};
  }

  ${media.phone`
    section {
      flex-direction: column;
      align-items: flex-start;
      padding: ${setRem(12)};
      padding-bottom: ${setRem(48)};
    }
    .title-container {
      justify-content: flex-start;
    }
  `}
`

export const query = graphql`
  {
    file(relativePath: { eq: "Alex.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Contact
